import { DEFAULT_BOUNDS } from './config';

export const mapFromElements = (input: string): string[] => input.split(/\s+/);

export const mapToElements = (input: string[]): string => input.join(' ');

export const mapFromBounds = (input: string): number[] => {
  const newBounds = input.split(/\s+/).map((el) => Number(el));

  if (newBounds.length !== 2) {
    return DEFAULT_BOUNDS;
  }
  return newBounds;
};

export const mapFromTicks = (input: string): number[] =>
  input.split(/\s+/).map((el) => Number(el));

export const mapFromSpectroscopicData = (
  input: string
): Record<string, number[]> => {
  const data: Record<string, number[]> = {};

  const sanitizedInput = input.replace(/\s/g, '');

  const regex = /([A-Za-z]+)\[([\d.,]+)\]/g;
  let match;

  // Iterate over the matches and populate the data object
  while ((match = regex.exec(sanitizedInput)) !== null) {
    const element = match[1];
    const wavelengths = match[2].split(',').map(Number);
    data[element] = wavelengths;
  }

  return data;
};
