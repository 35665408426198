import { useEffect, RefObject } from 'react';

type ResizeHandler = (width: number, height: number) => void;

export const useResize = (
  targetRef: RefObject<HTMLDivElement>,
  cb: ResizeHandler
) => {
  useEffect(() => {
    const target = targetRef?.current;

    if (!target) return;

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const width = Math.floor(entry.contentRect.width);
        const height = Math.floor(entry.contentRect.height);

        cb(width, height);
      });
    });

    observer.observe(target);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [targetRef, cb]);
};
