import { IUpdateEntry } from 'asu-sim-toolkit';

export const versionHistory: IUpdateEntry[] = [
  // {
  //   date: new Date('2022-09-20').getTime(),
  //   title: '0.1.0 - Moving on out',
  //   description: 'Stuff, things and what not. Also - other stuff',
  //   changelog: {
  //     'Core functionality': [
  //       {
  //         description: 'App runs.',
  //         type: 'feature',
  //       },
  //     ],
  //     Visuals: [
  //       {
  //         description: 'See app run',
  //         type: 'feature',
  //       },
  //       {
  //         description: 'App is no longer invisible',
  //         type: 'fix',
  //       },
  //     ],
  //     'Code quality': [
  //       {
  //         description: 'Tests!',
  //         type: 'feature',
  //       },
  //     ],
  //   },
  // },
];
