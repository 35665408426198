import {
  CAPI,
  CAPI_TYPES,
  FullscreenCapiSchema,
  GodModeCapiSchema,
  IFullscreenCapiModel,
  IGodModeCapiModel,
  TCapiSchema,
} from 'asu-sim-toolkit';

import { isDebugMode } from './config';
import { SpectrumMode } from './stores/domain';
import { DEFAULT_AVAILABLE_ELEMENTS } from './stores/config';

export interface ICapiModel extends IFullscreenCapiModel, IGodModeCapiModel {
  'Sim.Mode': SpectrumMode;
  'Sim.TriggerCheckEvent': boolean;

  'Sim.Elements.MultiSelect': boolean;
  'Sim.Elements.Selected': string;
  'Sim.Elements.Available': string;
  'Sim.Elements.FBlockVisible': boolean;

  'Sim.Reference.Visible': boolean;
  'Sim.Reference.Elements': string;

  'Sim.Spectra.Visible': boolean;
  'Sim.Spectra.Bounds': string;
  'Sim.Spectra.Ticks': string;

  'Sim.Data.Input': string;
}

export const capiSchema: TCapiSchema<ICapiModel> = {
  ...GodModeCapiSchema,
  ...FullscreenCapiSchema,
  'Sim.Fullscreen.Visible': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Mode': {
    initialValue: SpectrumMode.Absorption,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(SpectrumMode),
    },
  },
  'Sim.TriggerCheckEvent': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Elements.MultiSelect': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Elements.Selected': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Elements.Available': {
    initialValue: DEFAULT_AVAILABLE_ELEMENTS,
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Elements.FBlockVisible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Reference.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Reference.Elements': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Spectra.Visible': {
    initialValue: true,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Spectra.Bounds': {
    initialValue: '380 750',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Spectra.Ticks': {
    initialValue: '400 500 600 700',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Data.Input': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
};

let capi: CAPI<ICapiModel>;

export function getCapi() {
  if (!capi) {
    capi = new CAPI<ICapiModel>(isDebugMode);
  }
  return capi;
}
