import { useContext } from 'react';

import { IRootStore } from '../../stores/types';
import { StoreContext } from './store-context';

export function useStores(): IRootStore {
  const context = useContext(StoreContext);

  if (context === null) {
    throw new Error('useStores must be used within StoreProvider');
  }

  return context;
}
