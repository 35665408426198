import { action, makeObservable, observable, reaction } from 'mobx';

import { ICapiModel } from '../capi';
import { IAppStore, IRootStore } from './types';
import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import {
  IPeriodicElement,
  PeriodicElementCategory,
  SpectrumMode,
} from './domain';
import {
  mapFromBounds,
  mapFromElements,
  mapFromSpectroscopicData,
  mapFromTicks,
  mapToElements,
} from './mappers';

import ELEMENTS_DATA from './periodic-elements.json';
import SPECTROSCOPIC_DATA from './spectroscopic-data.json';
import { DEFAULT_BOUNDS, DEFAULT_SPECTRUM_TICKS } from './config';

export class AppStore extends CapiBoundStore<ICapiModel> implements IAppStore {
  private readonly rootStore: IRootStore;
  isReady: boolean;
  periodicElements: IPeriodicElement[];
  activeReconstructedSpectrum: number[][];
  activeReferenceSpectrum: number[][];

  spectrumMode: SpectrumMode;
  isTriggerCheck: boolean;

  isMultiselect: boolean;
  availableElements: string[];
  selectedElements: string[];
  isFBlockVisible: boolean;

  isReferenceSpectraVisible: boolean;
  referenceElements: string[];

  isReconstructedSpectraVisible: boolean;
  spectraBounds: number[];
  ticks: number[];

  overwrittenSpectroscopicData: Record<string, number[]>;

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);
    this.rootStore = rootStore;

    this.isReady = false;
    this.periodicElements = [];
    this.activeReconstructedSpectrum = [];
    this.activeReferenceSpectrum = [];

    this.spectrumMode = SpectrumMode.Absorption;
    this.isTriggerCheck = false;

    this.isMultiselect = true;
    this.availableElements = [];
    this.selectedElements = [];
    this.isFBlockVisible = true;

    this.isReferenceSpectraVisible = true;
    this.referenceElements = [];

    this.isReconstructedSpectraVisible = true;
    this.spectraBounds = DEFAULT_BOUNDS;
    this.ticks = DEFAULT_SPECTRUM_TICKS;

    this.overwrittenSpectroscopicData = {};

    makeObservable(this, {
      isReady: observable,
      periodicElements: observable,
      activeReconstructedSpectrum: observable,
      activeReferenceSpectrum: observable,
      spectrumMode: observable,
      isTriggerCheck: observable,
      isMultiselect: observable,
      availableElements: observable,
      selectedElements: observable,
      isFBlockVisible: observable,
      isReferenceSpectraVisible: observable,
      referenceElements: observable,
      isReconstructedSpectraVisible: observable,
      spectraBounds: observable,
      ticks: observable,
      overwrittenSpectroscopicData: observable,

      togglePeriodicElement: action.bound,
      loadPeriodicElements: action.bound,
      createReferenceSpectrum: action,
      activatePeriodicElements: action,
      resetSelectedElements: action,
    });

    this.synchronizeFromCapi('spectrumMode', 'Sim.Mode');
    this.synchronizeFromCapi('isTriggerCheck', 'Sim.TriggerCheckEvent');
    this.synchronizeFromCapi('isMultiselect', 'Sim.Elements.MultiSelect');
    this.synchronizeFromCapi(
      'availableElements',
      'Sim.Elements.Available',
      mapFromElements
    );
    this.bindToCapi(
      'selectedElements',
      'Sim.Elements.Selected',
      mapFromElements,
      mapToElements
    );
    this.synchronizeFromCapi('isFBlockVisible', 'Sim.Elements.FBlockVisible');
    this.synchronizeFromCapi(
      'isReferenceSpectraVisible',
      'Sim.Reference.Visible'
    );
    this.synchronizeFromCapi(
      'referenceElements',
      'Sim.Reference.Elements',
      mapFromElements
    );
    this.synchronizeFromCapi(
      'isReconstructedSpectraVisible',
      'Sim.Spectra.Visible'
    );
    this.synchronizeFromCapi(
      'spectraBounds',
      'Sim.Spectra.Bounds',
      mapFromBounds
    );
    this.synchronizeFromCapi('ticks', 'Sim.Spectra.Ticks', mapFromTicks);
    this.synchronizeFromCapi(
      'overwrittenSpectroscopicData',
      'Sim.Data.Input',
      mapFromSpectroscopicData
    );

    reaction(
      () => [this.availableElements, this.overwrittenSpectroscopicData],
      () => {
        this.loadPeriodicElements();
      },
      {
        fireImmediately: true,
      }
    );
    reaction(
      () => this.selectedElements,
      () => {
        this.activatePeriodicElements();
      },
      {
        fireImmediately: true,
      }
    );
    reaction(
      () => this.referenceElements,
      () => {
        this.createReferenceSpectrum();
      },
      {
        fireImmediately: true,
      }
    );
    reaction(
      () => this.isMultiselect,
      () => {
        this.resetSelectedElements();
      }
    );
  }

  private getActiveSpectrum(activeElements: IPeriodicElement[]): number[][] {
    return activeElements.map((el) => el.spectrum);
  }

  createReferenceSpectrum() {
    const activeElements = this.periodicElements.filter((perEl) =>
      this.referenceElements.some((symbol) => symbol === perEl.symbol)
    );
    this.activeReferenceSpectrum = this.getActiveSpectrum(activeElements);
  }

  activatePeriodicElements() {
    this.periodicElements = this.periodicElements.map((element) => {
      if (this.selectedElements.includes(element.symbol)) {
        return { ...element, isActive: true };
      } else {
        return { ...element, isActive: false };
      }
    });
    const activeElements = this.periodicElements.filter((el) => el.isActive);
    this.activeReconstructedSpectrum = this.getActiveSpectrum(activeElements);
  }

  resetSelectedElements() {
    this.selectedElements = [];
  }

  togglePeriodicElement(symbol: string) {
    const elementIndex = this.selectedElements.indexOf(symbol);

    if (elementIndex !== -1) {
      this.selectedElements.splice(elementIndex, 1);
    } else if (this.isMultiselect) {
      this.selectedElements.push(symbol);
    } else {
      this.selectedElements = [symbol];
    }

    this.selectedElements = [...this.selectedElements];
    this.activatePeriodicElements();

    if (this.isTriggerCheck) {
      this.capi.triggerCheck();
    }
  }

  loadPeriodicElements() {
    this.periodicElements = ELEMENTS_DATA.elements.map(
      ({ number, symbol, name, category, atomic_mass, xpos, ypos }) => {
        const baseCategory = RegExp('unknown').test(category)
          ? 'unknown'
          : (category as PeriodicElementCategory);

        const spectrum =
          this.overwrittenSpectroscopicData[symbol] ||
          SPECTROSCOPIC_DATA[name as keyof typeof SPECTROSCOPIC_DATA] ||
          [];

        return {
          atomicNumber: number,
          symbol,
          name,
          category: baseCategory,
          mass: atomic_mass,
          spectrum,
          isActive: false,
          isDisabled:
            !this.availableElements.includes(symbol) || spectrum.length === 0,
          x: xpos,
          y: ypos,
        };
      }
    );
    this.resetSelectedElements();
    this.isReady = true;
  }
}
