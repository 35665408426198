import classNames from 'classnames';
import React, { FC } from 'react';

interface ISpinnerProps {
  className?: string;
  scale?: number;
}

export const Spinner: FC<ISpinnerProps> = ({ className, scale = 1 }) => {
  const getSize = (base: number) => Math.round(base * scale);

  return (
    <div
      className={classNames(
        'flex justify-center items-center w-full h-full',
        className
      )}
    >
      <div
        className="animate-spin inline-block border-current border-t-transparent text-gogo rounded-full"
        style={{
          width: getSize(34),
          height: getSize(34),
          borderWidth: getSize(4),
        }}
        role="status"
        aria-label="loading"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
