import React, { FC, ReactNode } from 'react';

import { StoreContext } from './store-context';
import { IRootStore } from '../../stores/types';

export interface IStoreProviderProps {
  children: ReactNode;
  store: IRootStore;
}

export const StoreProvider: FC<IStoreProviderProps> = ({ store, children }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);
