import {
  RecapStore,
  ModalStore,
  NotificationStore,
  INotificationStore,
  IModalStore,
  IRecapStore,
  IFullscreenStore,
  FullscreenStore,
  SimContextStore,
  ISimContextStore,
  CAPI,
} from 'asu-sim-toolkit';

import { isDebugMode } from '../config';
import { ICapiModel } from '../capi';
import { IAppStore, IRootStore } from './types';
import { AppStore } from './app-store';
import { versionHistory } from '../version-history';

export class RootStore implements IRootStore {
  appStore: IAppStore;
  notificationStore: INotificationStore;
  modalStore: IModalStore;
  recapStore: IRecapStore;
  fullscreenStore: IFullscreenStore;
  simContextStore: ISimContextStore;

  constructor(capi: CAPI<ICapiModel>) {
    if (isDebugMode) {
      (window as any).capi = capi;
      (window as any).store = this;
    }

    this.notificationStore = new NotificationStore();
    this.modalStore = new ModalStore();
    this.recapStore = new RecapStore(this.modalStore, versionHistory);
    this.fullscreenStore = new FullscreenStore(capi);
    this.simContextStore = new SimContextStore(capi);
    this.appStore = new AppStore(this, capi);
  }
}
