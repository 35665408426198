import classNames from 'classnames';
import React, { FC, useState, KeyboardEvent } from 'react';
import { IPeriodicElementData } from '../../stores/domain';
import { observer } from 'mobx-react-lite';

interface IPeriodicElementProps extends Omit<IPeriodicElementData, 'category'> {
  cw?: number;
  className?: string;
  isActive?: boolean;
  isDisabled?: boolean;

  onClick: (symbol: string) => void;
}

export const PeriodicElement: FC<IPeriodicElementProps> = observer(
  ({
    atomicNumber,
    symbol,
    name,
    mass,
    cw = 700,
    className,
    isActive,
    isDisabled,
    onClick,
  }) => {
    // Needed to programmatically set z-index. Otherwise it won't work.
    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>): void => {
      event.preventDefault();

      if ((event.key === ' ' || event.key === 'Enter') && !isDisabled) {
        onClick(symbol);
      }
    };

    return (
      <div
        className={classNames(
          /* (aspect calculation) height / width * 100% = 5 / 4 * 100% = 125% */
          'relative aspect-4/5 pb-[125%] h-0 text-white text-center rounded-md overflow-hidden transition-all leading-tight focus:outline-none select-none',
          (isFocused || isHovered) &&
            'z-20 scale-[140%] shadow-outer ring ring-cue w-[120%]',
          isActive && 'brightness-[1.5] ring-[1px] ring-white',
          isDisabled
            ? 'opacity-50 cursor-not-allowed pointer-events-none'
            : 'cursor-pointer',
          className
        )}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onPointerEnter={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)}
        onClick={() => onClick(symbol)}
        onKeyUp={handleKeyPress}
        tabIndex={!isDisabled ? 0 : undefined}
        role="gridcell"
        aria-label={
          !isDisabled
            ? `Periodic Element - ${symbol}`
            : `Disabled Periodic Element - ${symbol}`
        }
      >
        <div
          className="absolute top-[2px] left-[4px]"
          style={{ fontSize: cw * 0.01 }}
        >
          {atomicNumber}
        </div>
        <div
          className={classNames(
            'absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-[45%] transition-all'
          )}
        >
          <div className="flex flex-col justify-center items-center">
            <div className="font-semibold" style={{ fontSize: cw * 0.022 }}>
              {symbol}
            </div>
            <div
              className="truncate w-full px-[2px]"
              style={{
                fontSize: cw * (isHovered || isFocused ? 0.007 : 0.008),
              }}
            >
              {name}
            </div>
          </div>
          <div style={{ fontSize: cw * 0.008 }}>{Number(mass.toFixed(3))}</div>
        </div>
      </div>
    );
  }
);
