import {
  CANVAS_LABELS_HEIGHT,
  CANVAS_SPECTRUM_HEIGHT,
  SPECTRUM_GRADIENT_DATA,
} from '../../stores/config';
import { clamp } from '../../utils/clamp';

interface IOptions {
  isEmissionMode: boolean;
  bounds: number[];
}

interface IOptionsWithAbsorbedWavelengths extends IOptions {
  absorbedWavelengths: number[][];
}

interface ITickOptions {
  ticks: number[];
  bounds: number[];
}

export const calculatePosition = (value: number, bounds: number[]) => {
  const [minBound, maxBound] = bounds;
  const clampedValue = clamp(minBound, maxBound, value);
  const totalRange = maxBound - minBound;
  const startPosition = clampedValue - minBound;

  return startPosition / totalRange;
};

export const getTick = (value: number, bounds: number[]) => ({
  value,
  label: String(value),
  position: calculatePosition(value, bounds),
});

export const getSpectrumGradient = (
  context: CanvasRenderingContext2D,
  bounds: number[]
) => {
  const gradient = context.createLinearGradient(0, 0, context.canvas.width, 0);
  SPECTRUM_GRADIENT_DATA.forEach(({ startWavelength, color }) => {
    gradient.addColorStop(calculatePosition(startWavelength, bounds), color);
  });

  return gradient;
};

export const drawSpectrum = (
  context: CanvasRenderingContext2D,
  { isEmissionMode, bounds }: IOptions
) => {
  const width = context.canvas.width;
  const height = context.canvas.height - 30;

  const gradient = isEmissionMode
    ? 'rgb(24, 24, 24)'
    : getSpectrumGradient(context, bounds);

  context.fillStyle = gradient;
  context.fillRect(0, 0, width, height);
};

export const applyAbsorbedWavelengths = (
  context: CanvasRenderingContext2D,
  {
    absorbedWavelengths,
    isEmissionMode,
    bounds,
  }: IOptionsWithAbsorbedWavelengths
) => {
  const width = context.canvas.width;
  const height = CANVAS_SPECTRUM_HEIGHT;

  const color = isEmissionMode ? getSpectrumGradient(context, bounds) : 'black';

  absorbedWavelengths.forEach((wavelengths) => {
    wavelengths.forEach((wavelength) => {
      const startX = width * calculatePosition(wavelength, bounds);

      context.beginPath();
      context.moveTo(startX, 0);
      context.lineTo(startX, height);
      context.strokeStyle = color;
      context.lineWidth = 1;
      context.stroke();
    });
  });
};

export const drawTicks = (
  context: CanvasRenderingContext2D,
  { bounds, ticks }: ITickOptions
) => {
  const width = context.canvas.width;
  const height = context.canvas.height - CANVAS_LABELS_HEIGHT;

  const tickLabels = ticks
    .map((value) => getTick(value, bounds))
    .filter((el) => el.value > bounds[0] && el.value < bounds[1]);

  // Enable image smoothing for better text rendering quality
  context.imageSmoothingEnabled = true;

  tickLabels.forEach(({ label, position }) => {
    const xPos = Math.floor(width * position);

    context.beginPath();
    context.moveTo(xPos, height - 5);
    context.lineTo(xPos, height + 5);
    context.strokeStyle = 'white';
    context.lineWidth = 2;
    context.stroke();

    context.font = '16px Arial';
    context.fillStyle = 'white';
    context.textAlign = 'center';

    // Set the text baseline to middle for better vertical alignment
    context.textBaseline = 'middle';
    context.fillText(label, xPos, height + 20);
  });
};
