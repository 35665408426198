import React, { useRef, useEffect, useState } from 'react';

import { useResize } from '../../hooks/use-resize';
import { observer } from 'mobx-react-lite';
import {
  CANVAS_HEIGHT,
  CANVAS_LABELS_HEIGHT,
  DEFAULT_BOUNDS,
  DEFAULT_SPECTRUM_TICKS,
} from '../../stores/config';
import { applyAbsorbedWavelengths, drawSpectrum, drawTicks } from './helpers';

interface SpectrumProps {
  title?: string;
  absorbedWavelengths?: number[][];
  isEmissionMode?: boolean;
  bounds?: number[];
  ticks?: number[];
}

export const Spectrum: React.FC<SpectrumProps> = observer(
  ({
    title,
    absorbedWavelengths = [],
    isEmissionMode = false,
    bounds = DEFAULT_BOUNDS,
    ticks = DEFAULT_SPECTRUM_TICKS,
  }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);

    useResize(containerRef, (width) => {
      setContainerWidth(width);
    });

    useEffect(() => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext('2d');

      if (context && canvas) {
        canvas.width = containerWidth;
        canvas.height = CANVAS_HEIGHT;
        setContainerWidth(
          containerRef.current?.getBoundingClientRect().width || 0
        );

        drawSpectrum(context, { isEmissionMode, bounds });
        applyAbsorbedWavelengths(context, {
          absorbedWavelengths,
          isEmissionMode,
          bounds,
        });
        drawTicks(context, { bounds, ticks });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerWidth, absorbedWavelengths, isEmissionMode, bounds, ticks]);

    return (
      <div
        ref={containerRef}
        style={{
          width: '100%',
          height: CANVAS_HEIGHT,
          position: 'relative',
          marginBottom: CANVAS_LABELS_HEIGHT,
          marginTop: CANVAS_LABELS_HEIGHT,
        }}
      >
        {title && (
          <div className="text-white ml-md mb-[2px] text-center text-md text-semibold">
            {title}
          </div>
        )}
        <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
      </div>
    );
  }
);
