import React, { useRef, useState } from 'react';

import { PeriodicElement } from './PeriodicElement';
import { PeriodicElementPlaceholder } from './PeriodicElementPlaceholder';
import { useResize } from '../../hooks/use-resize';
import { useGridKeyboardControls } from '../../hooks/use-grid-keyboard-controls';
import { PeriodicElementCategory } from '../../stores/domain';
import { useStores } from '../../providers/store/use-stores';
import { observer } from 'mobx-react-lite';

const getCategoryColor = (category: PeriodicElementCategory) => {
  if (category === 'diatomic nonmetal' || category === 'polyatomic nonmetal') {
    return 'bg-reactiveNonmetal';
  }
  if (category === 'alkali metal') {
    return 'bg-alkaliMetal';
  }
  if (category === 'alkaline earth metal') {
    return 'bg-alkalineEarthMetal';
  }
  if (category === 'post-transition metal') {
    return 'bg-postTransitionMetal';
  }
  if (category === 'metalloid') {
    return 'bg-metalloid';
  }
  if (category === 'noble gas') {
    return 'bg-nobleGas';
  }
  if (category === 'transition metal') {
    return 'bg-transitionMetal';
  }
  if (category === 'actinide') {
    return 'bg-actinide';
  }
  if (category === 'lanthanide') {
    return 'bg-lanthanide';
  }
  return 'bg-unknown';
};

export const PeriodicTable = observer(() => {
  const {
    appStore: { periodicElements, togglePeriodicElement, isFBlockVisible },
  } = useStores();
  const gridRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useResize(gridRef, (width) => {
    setContainerWidth(width);
  });

  useGridKeyboardControls(gridRef, 21, 10);

  return (
    <div
      ref={gridRef}
      className="relative w-full min-w-[700px] max-w-[980px] mx-auto p-4 grid grid-cols-18 gap-1"
    >
      {periodicElements
        .filter(
          ({ category }) =>
            isFBlockVisible ||
            (category !== 'lanthanide' && category !== 'actinide')
        )
        .map(
          ({
            atomicNumber,
            name,
            symbol,
            category,
            mass,
            isActive,
            isDisabled,
            x,
            y,
          }) => {
            const bgColor = getCategoryColor(category);
            const column =
              category === 'lanthanide' || category === 'actinide' ? x + 1 : x;
            const className = `${bgColor} c${column} r${y}`;

            return (
              <PeriodicElement
                key={`PeriodicElement-${atomicNumber}`}
                cw={containerWidth}
                className={className}
                atomicNumber={atomicNumber}
                symbol={symbol}
                name={name}
                mass={mass}
                onClick={togglePeriodicElement}
                isActive={isActive}
                isDisabled={isDisabled}
              />
            );
          }
        )}
      {isFBlockVisible && (
        <>
          <PeriodicElementPlaceholder
            cw={containerWidth}
            text="57-71"
            className="c3 r6 bg-lanthanide opacity-[50%]"
          />
          <PeriodicElementPlaceholder
            cw={containerWidth}
            text="89-103"
            className="c3 r7 bg-actinide opacity-[50%]"
          />
          <PeriodicElementPlaceholder className="gap c3 r8" />
        </>
      )}
    </div>
  );
});
