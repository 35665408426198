export const DEFAULT_AVAILABLE_ELEMENTS =
  'H He Li Be B C N O F Ne Na Mg Al Si P S Cl Ar K Ca Sc Ti V Cr Mn Fe Co Ni Cu Zn Ga Ge As Se Br Kr Rb Sr Y Zr Nb Mo Tc Ru Rh Pd Ag Cd In Sn Sb Te I Xe Cs Ba La Ce Pr Nd Pm Sm Eu Gd Tb Dy Ho Er Tm Yb Lu Hf Ta W Re Os Ir Pt Au Hg Tl Pb Bi Po At Rn Fr Ra Ac Th Pa U Np Pu Am Cm Bk Cf Es Fm Md No Lr Rf Db Sg Bh Hs Mt Ds Rg Cn Nh Fl Mc Lv Ts Og';

export const DEFAULT_BOUNDS = [380, 750];

export const DEFAULT_SPECTRUM_TICKS = [400, 500, 600, 700];

export const CANVAS_HEIGHT = 130;
export const CANVAS_SPECTRUM_HEIGHT = 100;
export const CANVAS_LABELS_HEIGHT = CANVAS_HEIGHT - CANVAS_SPECTRUM_HEIGHT;

export const SPECTRUM_GRADIENT_DATA = [
  {
    startWavelength: 300,
    color: 'rgb(75, 0, 130)',
  },
  {
    startWavelength: 380,
    color: 'rgb(148, 0, 211)',
  },
  {
    startWavelength: 440,
    color: 'rgb(0, 255, 255)',
  },
  {
    startWavelength: 485,
    color: 'rgb(0, 255, 255)',
  },
  {
    startWavelength: 510,
    color: 'rgb(0, 255, 0)',
  },
  {
    startWavelength: 565,
    color: 'rgb(255, 255, 0)',
  },
  {
    startWavelength: 590,
    color: 'rgb(255, 165, 0)',
  },
  {
    startWavelength: 625,
    color: 'rgb(255, 0, 0)',
  },
  {
    startWavelength: 740,
    color: 'rgb(139, 0, 0)',
  },
];
