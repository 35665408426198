export type PeriodicElementCategory =
  | 'diatomic nonmetal'
  | 'noble gas'
  | 'alkali metal'
  | 'alkaline earth metal'
  | 'metalloid'
  | 'polyatomic nonmetal'
  | 'post-transition metal'
  | 'transition metal'
  | 'lanthanide'
  | 'actinide'
  | 'unknown';

export interface IPeriodicElementData {
  atomicNumber: number;
  symbol: string;
  name: string;
  category: PeriodicElementCategory;
  mass: number;
}

export interface IPeriodicElement extends IPeriodicElementData {
  spectrum: number[];
  isActive: boolean;
  isDisabled: boolean;
  x: number;
  y: number;
}

export enum SpectrumMode {
  Absorption = 'Absorption',
  Emission = 'Emission',
}
