import { useEffect } from 'react';

export function useGridKeyboardControls(
  gridRef: React.RefObject<HTMLElement>,
  columns: number,
  rows: number
) {
  function handleKeyDown(event: KeyboardEvent) {
    const { key } = event;

    if (key === 'w' || key === 'W' || key === 'ArrowUp') {
      event.preventDefault();
      focusElement(0, -1);
    } else if (key === 's' || key === 'S' || key === 'ArrowDown') {
      event.preventDefault();
      focusElement(0, 1);
    } else if (key === 'a' || key === 'A' || key === 'ArrowLeft') {
      event.preventDefault();
      focusElement(-1, 0);
    } else if (key === 'd' || key === 'D' || key === 'ArrowRight') {
      event.preventDefault();
      focusElement(1, 0);
    }
  }

  function focusElement(columnOffset: number, rowOffset: number) {
    if (!gridRef.current) return;
    const gridItems = Array.from(gridRef.current.childNodes) as HTMLElement[];
    const currentElement = gridItems.find(
      (item) => item === document.activeElement
    );

    if (!currentElement) {
      (gridRef.current.childNodes[0] as HTMLElement).focus();
      return;
    }

    const regex = /c(\d+)\sr(\d+)/;
    const matches = currentElement.className.match(regex);

    if (!matches) return;

    // Retrieve the first and second captured group from RegExp
    const currentColumn = matches[1];
    const currentRow = matches[2];

    let newColumn = Number(currentColumn) + columnOffset;
    let newRow = Number(currentRow) + rowOffset;

    while (
      newColumn > 0 &&
      newColumn <= columns &&
      newRow > 0 &&
      newRow <= rows
    ) {
      const classRegExp = new RegExp(`\\bc${newColumn}\\sr${newRow}\\b`);

      const newElement = gridItems.find(
        (el) =>
          el.getAttribute('aria-label')?.startsWith('Periodic Element') &&
          classRegExp.test(el.className)
      );

      if (newElement) {
        newElement.focus();
        break;
      }

      newColumn += columnOffset;
      newRow += rowOffset;
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef]);
}
