import classNames from 'classnames';
import React, { FC } from 'react';

interface IPeriodicElementPlaceholderProps {
  cw?: number;
  text?: string;
  className?: string;
}

export const PeriodicElementPlaceholder: FC<
  IPeriodicElementPlaceholderProps
> = ({ cw = 700, text, className }) => (
  <div
    className={classNames(
      /* (aspect calculation) height / width * 100% = 5 / 4 * 100% = 125% */
      'relative aspect-4/5 pb-[125%] h-0 w-full text-white text-center transition-all leading-tight rounded-md',
      className
    )}
  >
    {text && (
      <div className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="font-semibold" style={{ fontSize: cw * 0.012 }}>
          {text}
        </div>
      </div>
    )}
  </div>
);
